export const achievementData = {
    bio : "Putting quantified achievements & Relevent Course Work on a resume is great, but I just don’t work with hard numbers. I have no data to support the quality of my work. If you have lots of relevant experience, the heading statement of your resume will be a summary.",
    achievements : [
        {
            id : 1,
            title : 'Cisco Certified Network Associate (CCNA)',
            details : 'CCNA certification proves you have what it takes to navigate the ever-changing landscape of IT. CCNA exam covers networking fundamentals, IP services, security fundamentals, automation and programmability and much more.',
            date : '2022',
            field : 'Networking',
            image : ''
        },
        {
            id : 2,
            title : 'Freelancing Digiskills ',
            details : 'This course is meant to teach the tips and tricks of freelancing in the simplest and effective way. The do`s and don`ts are discussed, about which a freelancer must be aware of. Introduction of different types of freelance marketplaces is given in this course. ',
            date : '2021',
            field : 'Freelancing',
            image : ''
        },
        {
            id : 3,
            title : 'Digital Marketing',
            details : 'This course aims to provide trainees a deeper understanding of planning of a digital marketing campaign and the role of various digital channels in achieving that goal through integrated marketing communication',
            date : '2021',
            field : 'Marketing',
            image : ''
        },
/*        {
            id : 4,
            title : 'Certificate in Information Technology (CIT)',
            details : 'Computer information technology (CIT) is the use and study of computers, networks, computer languages and databases within an organization to solve real problems.',
            date : '2019',
            field : 'IT',
            image : ''
        }
*/
    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/