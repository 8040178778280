export const experienceData = [
    {
        id: 1,
        company: 'Nanotechx Pvt. Ltd.',
        jobtitle: 'Content Writer',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Indigo Textiles',
        jobtitle: 'Quality Control Analyst',
        startYear: '2019',
        endYear: 'Present'
    },
    {
        id: 3,
        company: 'Archroma Chemicals',
        jobtitle: 'Internship',
        startYear: '2018',
        endYear: ''
    },
    // {
    //     id: 4,
    //     company: 'Archroma Chemicals',
    //     jobtitle: 'Internship',
    //     startYear: '2018',
    //     endYear: ''
    // },
]