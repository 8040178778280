import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'

// build projects
export const projectsData = [
   {
        id: 1,
        projectName: 'Fabrication of anion exchange membrane',
        projectDesc: 'Fabrication of anion exchange membrane for water desalination process and water filtration scheme, with Dr. Shazia Perveen and me Ruba Khawar',
        tags: ['React', 'CSS', 'Material Ui'],
        code: '',
        demo: '',
        image: one
    },
    {
        id: 2,
        projectName: 'Recycling in Industrial Chemical Waste',
        projectDesc: 'Synthesis of Nano-Material Induced Ion Exchange Membrane for Recycling in Industrial Chemical Waste, with Dr. Shazia Perveen and me Ruba Khawar',
        tags: ['Django', 'Tailwind CSS'],
        code: '',
        demo: '',
        image: two
    },
    {
        id: 3,
        projectName: 'All Projects Here',
        projectDesc: 'I have gathered all my projects in one place.',
        tags: ['React', 'CSS', 'Material Ui'],
        code: 'https://nanotechx.org/ruba-khawar/',
        demo: 'https://nanotechx.org/ruba-khawar/',
        image: one
    },
    {
        id: 4,
        projectName: 'Technical Writing',
        projectDesc: 'This project is a railway tracking system that allows users to obtain relevant information about train timing.',
        tags: ['Django', 'Tailwind CSS'],
        code: 'https://docs.google.com/document/d/1FwEjGDcHnazf87w41MnBJVV1FjUlykZRKwqFxQ1zYZg/edit',
        demo: 'https://docs.google.com/document/d/1FwEjGDcHnazf87w41MnBJVV1FjUlykZRKwqFxQ1zYZg/edit',
        image: two
    },
  /*  {
        id: 5,
        projectName: 'E-Commerce App',
        projectDesc: 'A Simple E-commerce application',
        tags: ['React Native', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: five
    },
    {
        id: 6,
        projectName: 'Uber Lite',
        projectDesc: 'Uber clone',
        tags: ['Flutter'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: six
    },
    {
        id: 7,
        projectName: 'Stock Market App',
        projectDesc: 'A simple stock market API app',
        tags: ['React', 'Redux', 'Bootstrap'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: seven
    },
    {
        id: 8,
        projectName: 'Car Pooling System',
        projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
        tags: ['Flutter', 'React'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: eight
    },*/
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/