export const educationData = [
    {
        id: 1,
        institution: 'NED University of Engineering and Technology',
        course: 'MS (Industrial Chemistry)',
        startYear: '2019',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'NED University of Engineering and Technology',
        course: 'BS (Industrial Chemistry)',
        startYear: '2015',
        endYear: '2019'
    },
    // {
    //     id: 3,
    //     institution: 'University of Sindh',
    //     course: 'Bachelor of Information Technology (BS-IT)',
    //     startYear: '2022',
    //     endYear: 'Present'
    // },
]